import { environment } from "../environments/environment";

const baseUrl = environment.baseUrl;
const API = Object.freeze({
  getUser: `${baseUrl}/users/getUser`,
  postUser: `${baseUrl}/users/createUser`,
  getPostById: `${baseUrl}/api/posts/get_post_by_id`,
  postStartUpData: `${baseUrl}/startup/createStartup`,
  deleteStartUp:`${baseUrl}/startup/delete_startup`,
  loginUser: `${baseUrl}/users/login`,
  blockuser: `${baseUrl}/users/blockuser`,
  getUserByIdBody: `${baseUrl}/users/getUserByIdBody`,
  unblockUser : `${baseUrl}/users/unblockuser`,
  linkdinLogin:`${baseUrl}/users/linkdin_login`,
  getLinkedInProfile:`${baseUrl}/users/getLinkedInProfile`,
  postUserPost: `${baseUrl}/api/posts/newPost`,
  uploadDocument: `${baseUrl}/upload`,
  getDocument: `${baseUrl}/documentation/getDocumentsByUser`,
  getAllPosts: `${baseUrl}/api/posts/getposts`,
  updateUser: `${baseUrl}/users/updateFounder`,
  getOnePager: `${baseUrl}/startUp/getOnePager`,
  getUserById: `${baseUrl}/users/getUserById`,
  getStartupByFounderId: `${baseUrl}/startup/getStartupByFounderId`,
  getStartUpById: `${baseUrl}/startup/getStartupById`,
  updateUserById: `${baseUrl}/users/updateUserById`,
  updateIntroMessage: `${baseUrl}/startup/introMessage`,
  getSavedPosts: `${baseUrl}/api/posts/savedPosts`,
  postResetPaswordLink: `${baseUrl}/users/requestPasswordReset`,
  postNewPassword: `${baseUrl}/users/resetPassword`,
  investNow: `${baseUrl}/startUp/investNow`,
  changePassword: `${baseUrl}/users/changePassword`,
  likeUnlikePost: `${baseUrl}/api/posts/likeUnlikePost`,
  pendingConnectionRequests: `${baseUrl}/connections/getPendingConnectionRequests`,
  sentConnectionRequests: `${baseUrl}/connections/getSentPendingConnectionRequests`,
  acceptConnectionRequest: `${baseUrl}/connections/acceptConnectionRequest`,
  rejectConnectionRequest: `${baseUrl}/connections/rejectConnectionRequest`,
  getRecommendations: `${baseUrl}/connections/getRecommendations`,
  getSavedPostCollections: `${baseUrl}/api/posts/getSavedPostCollections`,
  getSavedPostsByCollection: `${baseUrl}/api/posts/getSavedPostsByCollection`,
  getUserConnections: `${baseUrl}/connections/getUserConnections`,
  sendConnectionRequest: `${baseUrl}/connections/sendConnectionRequest`,
  sendPostComment: `${baseUrl}/api/posts/comment`,
  getPostComment: `${baseUrl}/api/posts/getComments`,
  cancelConnectionRequest: `${baseUrl}/connections/cancelConnectionRequest`,
  getSearchResults: `${baseUrl}/users/search`,
  savePostByUserId: `${baseUrl}/api/posts/savePost`,
  deletePost: `${baseUrl}/api/posts/deletePost`,
  getUserChats: `${baseUrl}/chat/getUserChats`,
  createChat: `${baseUrl}/chat/createChat`,
  addMessage: `${baseUrl}/message/addMessage`,
  getSinglePost: `${baseUrl}/api/posts/getSinglePost`,
  findChat: `${baseUrl}/chat/findChat`,
  markMessagesAsRead: `${baseUrl}/message/markMessagesAsRead`,
  getUnreadMessageCount: `${baseUrl}/message/getUnreadMessageCount`,
  getTotalUnreadMessagesCount: `${baseUrl}/message/getAllUnreadMessageCount`,
  getUserByUserName : `${baseUrl}/users/getUserByUserName`,
  togglePinMessage: `${baseUrl}/chat/togglePin`,
  getPinnedChat: `${baseUrl}/chat/getPinnedChat`,
  getInvestorById: `${baseUrl}/investor/getInvestorById`,
  postInvestorData: `${baseUrl}/investor/createInvestor`,
  addStartupInvested: `${baseUrl}/investor/addStartupInvested`,
  addSectorOfInterest: `${baseUrl}/investor/addSectorOfInterest`,
  uploadLogo: `${baseUrl}/investor/uploadLogo`,
  getMessageByChatId: `${baseUrl}/message/getMessages`,
  clearChat:`${baseUrl}/message/clearChat`,
  createCommunity: `${baseUrl}/community/createCommunity`,
  getAllCommunity: `${baseUrl}/community/getAllCommunitiesByUserId`,
  addMyInterest: `${baseUrl}/investor/addMyInterest`,
  getFeaturedPostsByUser: `${baseUrl}/api/posts/getFeaturedPostsByUser`,
  getCompanyUpdatePosts: `${baseUrl}/api/posts/getCompanyUpdatePosts`,
  addToFeaturedPost: `${baseUrl}/api/posts/addToFeaturedPost`,
  addToCompanyUpdate:`${baseUrl}/api/posts/addToCompanyUpdatePost`,
  getCommunityById: `${baseUrl}/community/getCommunityById`,
  getChatSettings: `${baseUrl}/chat/getChatSettings`,
  getCommunitySettings: `${baseUrl}/community/getCommunitySettings`,
  deleteComment: `${baseUrl}/api/posts/deleteComment`,
  likeComment: `${baseUrl}/api/posts/toggleLikeComment`,
  unsavePost: `${baseUrl}/api/posts/unsavePost`,
  removeFromFeaturedPost: `${baseUrl}/api/posts/removeFromFeaturedPost`,
  removeCompanyUpdatePost:`${baseUrl}/api/posts/removeCompanyUpdatePost`,
  getUserPost : `${baseUrl}/api/posts/user_post`,
  deleteMessage: `${baseUrl}/message/deleteMessage`,
  getNotifications: `${baseUrl}/notificaton/getNotification`,
  markNotificationAsRead: `${baseUrl}/notificaton/markMessageAsRead`,
  markAllNotificationAsRead: `${baseUrl}/notificaton/markAllNotificationsAsRead`,
  getLikeCount: `${baseUrl}/api/posts/likeCount`,
  searchStartUps: `${baseUrl}/startup/searchStartUps`,
  addStartUpToUser: `${baseUrl}/users/addStartUpToUser`,
  deleteDocument: `${baseUrl}/documentation/deleteDocument`,
  markMessagesAsReadInCommunities: `${baseUrl}/message/markMessagesAsReadInCommunities`,
  getUnreadMessageCountInCommunities: `${baseUrl}/message/getUnreadMessageCountInCommunities`,
  getExploreFilters: `${baseUrl}/users/exploreFilters`,
  getExploreFilteredData: `${baseUrl}/users/explore`,
  searchInvestors: `${baseUrl}/investor/searchInvestors`,
  addUserAsInvestor: `${baseUrl}/users/addUserAsInvestor`,
  updateCommunity: `${baseUrl}/community/updateCommunity`,
  getUnAddedMembers: `${baseUrl}/community/getUnAddedMembers`,
  addMembersToCommunity: `${baseUrl}/community/addMembersToCommunity`,
  createSecretKey: `${baseUrl}/users/createSecretKey`,
  validateSecretKey: `${baseUrl}/users/validateSecretKey`,
  getInvestorFromOneLink: `${baseUrl}/investor/getOnePager`,
  createMeeting: `${baseUrl}/schedule/createMeeting`,
  createMeetingLink: `${baseUrl}/schedule/createMeetingLink`,
  validateMeetingAuth: `${baseUrl}/schedule/validateMeetingAuth`,
  getAllMeetings: `${baseUrl}/schedule/getAllMeetings`,
  deleteMeeting: `${baseUrl}/schedule/deleteMeeting`,
  removeConnection: `${baseUrl}/connections/removeConnection`,
  requestMeeting: `${baseUrl}/schedule/requestBookingSlot`,
  getAllMeetingRequests: `${baseUrl}/schedule/getAllRequests`,
  acceptMeetingRequest: `${baseUrl}/schedule/acceptMeetingRequest`,
  getNotificationCount: `${baseUrl}/notificaton/getNotificationCount`,
  deleteCommunity: `${baseUrl}/community/deleteCommunity`,
  exitCommunity: `${baseUrl}/community/exitCommunity`,
  getfolders: `${baseUrl}/documentation/getFolderByUser`,
  getAllMileStone: `${baseUrl}/startup/getAllMileStone`,
  getUserMilestones: `${baseUrl}/startup/getUserMilestones`,
  addMilestoneToUser: `${baseUrl}/startup/addMilestoneToUser`,
  deleteUserMilestone: `${baseUrl}/startup/deleteUserMilestone`,
  getQuestions: `${baseUrl}/question/getQuestions`,
  googleLogin: `${baseUrl}/users/googleLogin`,
  answerQuestion: `${baseUrl}/question/answerQuestion`,
  getQuestionCount: `${baseUrl}/question/getQuestionCount`,
  addRecentExperience: `${baseUrl}/users/addExperience`,
  addRecentEducation: `${baseUrl}/users/addEducation`,
  updateRecentEducation: `${baseUrl}/users/updateEducation`,
  deleteRecentEducation: `${baseUrl}/users/deleteEducation`,
  updateRecentExperience: `${baseUrl}/users/updateExperience`,
  deleteRecentExperience: `${baseUrl}/users/deleteExperience`,
  getLastMessage: `${baseUrl}/message/getLastMessage`,
  rejectMeetingRequest: `${baseUrl}/schedule/rejectMeetingRequest`,
  getUserAchievements: `${baseUrl}/achievement/getUserAchievements`,
  submitFundingToMail: `${baseUrl}/contactUs/lookingForFundingMail`,
  addNotificationAPI: `${baseUrl}/notificaton/addNotification`,
  getAllChats: `${baseUrl}/chat/getAllChats`,
  addPastInvestments: `${baseUrl}/investor/addPastInvestmentsController`,
  sendOtp: `${baseUrl}/users/send_otp`,
  verifyOtp: `${baseUrl}/users/verify_otp`,
  liveDeals:`${baseUrl}/live_deal/add_to_live_deals`,
  getLiveDeals:`${baseUrl}/live_deals/get_live_deals`,
  addInvestorToLiveDeal:`${baseUrl}/live_deals/add_investor_to_live_deal`,
  addArticle:`${baseUrl}/article/add_article`,
  subscription:`${baseUrl}/subscription/create_subscription`,
  getPaymentDetail:`${baseUrl}/subscription/get_subscription`
});

export default API;
